import React from "react";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Grid,
  Box,
  Divider,
} from "@mui/material";

const ProductCard = ({ products }) => {
  return (
    <Card sx={{ maxWidth: "100%", m: 2 }}>
      <CardContent>
        <Typography
          variant="h2" // Cambia a "h2" para el título
          component="div"
          align="center"
          sx={{
            fontFamily: "Nunito Sans, sans-serif",
            fontSize: { xs: "2rem", md: "3.75rem" }, // Ajuste de tamaño de fuente para xs y md
            fontWeight: "700",
            marginBottom: "10px",
            marginTop: "10px",
          }}
        >
          MÁS VENDIDOS
        </Typography>
        <Divider sx={{ my: 2, borderBottomWidth: 2, bgcolor: "black" }} />
        <Grid container spacing={2} sx={{ my: 2 }}>
          {products.map((product, index) => (
            <Grid item xs={6} sm={6} md={3} key={index}>
              {" "}
              {/* En xs dos productos por fila */}
              <Box display="flex" flexDirection="column" alignItems="center">
                <a
                  href={`/Producto?product=${product.type}&id=${product.id}`}
                  target="_blank"
                  rel="noreferrer"
                  style={{ textDecoration: "none" }}
                >
                  <CardMedia
                    component="img"
                    sx={{
                      height: { xs: "150px", md: "250px" }, // Ajuste solo en xs
                      width: { xs: "150px", md: "250px" }, // Ajuste solo en xs
                    }}
                    image={product.url}
                    alt={product.name}
                  />
                </a>
                <Typography
                  gutterBottom
                  variant="h7" // Sin cambios en la variant para md
                  component="div"
                  align="center"
                  sx={{
                    fontSize: { xs: "0.7rem", md: "inherit" },
                    fontWeight: { xs: "900", md: "inherit" },
                  }} // Ajuste solo en xs
                >
                  {product.name}
                </Typography>
                {product.disableOil && (
                  <Typography
                    variant="h3" // Sin cambios en la variant para md
                    component="div"
                    align="center"
                    sx={{ fontSize: { xs: "1.1rem", md: "40px" } }} // Ajuste solo en xs
                  >
                    ${product.price.toLocaleString("en-US")}{" "}
                    {/* Formato con comas */}
                  </Typography>
                )}
                {!product.disableOil && (
                  <Typography
                    variant="h3" // Sin cambios en la variant para md
                    component="div"
                    align="center"
                    sx={{ fontSize: { xs: "1.1rem", md: "40px" } }} // Ajuste solo en xs
                  >
                    $
                    {Math.min(
                      ...product.liters.map((item) => item.price)
                    ).toLocaleString("en-US")}{" "}
                    {/* Formato con comas */}
                  </Typography>
                )}
                <Typography
                  variant="body3" // Sin cambios en la variant para md
                  align="center"
                  sx={{ fontSize: { xs: "0.6rem", md: "inherit" } }} // Ajuste solo en xs
                >
                  <Box component="span" color="error.main">
                    envío GRATIS
                  </Box>
                  <Box component="span">
                    &nbsp;a partir de $549 en tu carrito
                  </Box>
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
        <Divider sx={{ my: 2, borderBottomWidth: 2, bgcolor: "black" }} />
      </CardContent>
    </Card>
  );
};

export default ProductCard;
