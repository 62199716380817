import { useState, useEffect } from "react";
import CurrencyFormat from "react-currency-format";

/* Routing */
import { Link } from "react-router-dom";

/* Icons */
import DeleteIcon from "@material-ui/icons/Delete";
import imageBroken from "assets/img/image.svg";

/* Material-UI */
import TextField from "@mui/material/TextField";

/* API */
import { getProductAPI } from "utils/API/Product";
import { getPreviewImageUrlFromProduct } from "utils/API/Pictures";

const card = {
  padding: "15px",
  backgroundColor: "white",
  fontSize: "20px",
  fontWeight: "300",
  display: "flex",
  justifyContent: "space-between",
};

const imgStyle = {
  cursor: "pointer",
  width: "80px",
  height: "80px",
};

const titleStyleMobile = {
  fontSize: "13px",
  marginBottom: "5px",
  fontFamily: "Nunito Sans, sans-serif",
  fontWeight: "400",
};

const infoContainer = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  textAlign: "left",
  marginRight: "5px",
};

const verticalLine = {
  width: "1px",
  height: "20px",
  marginLeft: "10px",
  marginRight: "5px",
  backgroundColor: "black",
};

export default function ShoppingCarProductMobile({
  id,
  type,
  description,
  price,
  amount,
  updateAmount,
  deleteItem,
}) {
  useEffect(() => {
    async function go() {
      if (!(id === null && type === null)) {
        var product = await getProductAPI(id, type);
        if (product !== null) {
          var url = await getPreviewImageUrlFromProduct(id, type);
          setTitle(product.name);
          setUrl(url);
        }
      }
    }

    go();
  }, [id, type]);

  const [title, setTitle] = useState("");
  const [url, setUrl] = useState(null);

  return (
    <div style={card}>
      <div style={infoContainer}>
        <div style={titleStyleMobile}>
          {" "}
          {title} {description ? `(${description})` : ""}
        </div>
        <div style={titleStyleMobile}>
          {" "}
          Precio unit:{" "}
          <CurrencyFormat
            prefix={"$ "} // Agrega el símbolo de $
            value={parseFloat(price).toFixed(2)}
            thousandSeparator={true}
            decimalScale={2}
            fixedDecimalScale={true}
            displayType={"text"}
          />{" "}
        </div>
        <div
          style={{ ...titleStyleMobile, fontWeight: "800", display: "flex" }}
        >
          {" "}
          Precio{" "}
          <CurrencyFormat
            prefix={"$ "} // Agrega el símbolo de $
            value={parseFloat(price * amount).toFixed(2)}
            thousandSeparator={true}
            decimalScale={2}
            fixedDecimalScale={true}
            displayType={"text"}
          />{" "}
          <div style={verticalLine} />
          <DeleteIcon
            style={{ cursor: "pointer", fontSize: "20px" }}
            onClick={deleteItem}
          />
        </div>
      </div>

      <div>
        {url === null && (
          <Link to={`/Producto?product=${type}&id=${id}`} target="_blank">
            <img src={imageBroken} alt="car" style={imgStyle} />
          </Link>
        )}
        {url !== null && (
          <Link to={`/Producto?product=${type}&id=${id}`} target="_blank">
            <img src={url} alt="car" style={imgStyle} />
          </Link>
        )}
        <div style={titleStyleMobile}>Cantidad:</div>
        <TextField
          inputProps={{
            min: 0,
            style: {
              textAlign: "center",
              fontSize: "13px",
              padding: "0",
            },
          }}
          size="small"
          style={{
            padding: "0",
            width: "40px",
            display: "inline-block",
          }}
          value={amount}
          onChange={(e) => updateAmount(e.target.value)}
        />
      </div>
    </div>
  );
}
