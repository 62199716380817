import { useEffect, useState } from "react";

/* Material UI */
import { Button } from "@material-ui/core";

/* Components */
import Paypal from "../../components/PayPal";

/* Sections */
import Products from "./Sections/Products";
import ShoppingCart from "./Sections/ShoppingCart";
import PayInfo from "./Sections/PayInfo";

/* Helpers */
import { convertToShoppingCartElement } from "helpers/helpers";

/* Icons */
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { addNewSell } from "utils/API/VentasAPI";
import {
  sendClientEmail,
  sendAfinazoneEmail,
} from "utils/EmailSender/EmailSender";
import ShoppingCartMobile from "./Sections/ShoppingCartMobile";
import { Typography } from "@mui/material";
import { getPreviewImageUrlFromProduct } from "utils/API/Pictures";

const container = {
  paddingLeft: "10%",
  paddingRight: "10%",
  paddingTop: "100px",
  minHeight: "60vh",
  fontSize: "40px",
  textAlign: "left",
  marginBottom: "40px",
};

const containerMobile = {
  paddingLeft: "5%",
  paddingRight: "5%",
  minHeight: "60vh",
  fontSize: "18px",
  fontWeight: "800",
  textAlign: "center",
  fontFamily: "Nunito Sans, sans-serif",
};

const sections = {
  display: "flex",
};

const sectionsMobile = {
  display: "flex",
  flexDirection: "column",
};

const productSection = "60%";
const productSectionMobile = "100%";

const shoppingCartSection = "40%";
const shoppingCartSectionMobile = "100%";

const paybutton = {
  width: "40%",
};

const payinfo = {
  width: "60%",
};

const labelStyle = {
  fontSize: "20px",
};

const fieldStyle = {
  marginBottom: "20px",
  maxWidth: "500px",
};

const paybuttonMobile = {
  width: "100%",
};

const payinfoMobile = {
  width: "100%",
};

export default function Carrito() {
  const [items, setItems] = useState([]);
  const [price, setPrice] = useState(0);
  const [pay, setPay] = useState(0);

  /* Formulario */

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [street, setStreet] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [colony, setColony] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [addressReference, setAddressReference] = useState("");
  const [phone, setPhone] = useState("");

  const validatePhone = (phone) => {
    if (phone.length > 10) return;
    const validNumber = new RegExp("^[0-9]*$");

    if (validNumber.test(phone)) setPhone(phone);
  };

  const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  useEffect(() => {
    async function go() {
      const shoppingCartItems = convertToShoppingCartElement();
    
      if (shoppingCartItems) {
        // Loop through each item to get the URL
        const updatedItems = await Promise.all(shoppingCartItems.map(async (item) => {
          const url = await getPreviewImageUrlFromProduct(item.id, item.type);
          return { ...item, url }; // Add the URL to the item
        }));
        setItems(updatedItems); // Update the state with the modified items
      }
    }

    go();
  }, []);

  const [allAmounts, setAllAmounts] = useState(true);

  useEffect(() => {
    var price = 0;
    var ok = true;
    for (var item of items) {
      price += item.price * item.amount;
      if (item.amount === "") ok = false;
    }
    setAllAmounts(ok);
    setPrice(parseFloat(price).toFixed(2));
  }, [items]);

  const createSellOrder = async () => {
    function formatDateToDDMMYYYYHHMM() {
      const date = new Date();

      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is zero-based
      const year = date.getFullYear();
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");

      return `${day}-${month}-${year} ${hours}:${minutes}`;
    }

    const date = formatDateToDDMMYYYYHHMM();

    const products = items.map((item) => {
      return {
        quantity: item.amount,
        description: item.description,
        name: item.name,
        type: item.type,
        uuid: item.id,
        price: item.price,
      };
    });

    const ok = await addNewSell({
      realDate: new Date(),
      date,
      name,
      email,
      street,
      postalCode,
      colony,
      city,
      state,
      country,
      phone,
      referenceAddress: addressReference,
      status: "pendiente",
      products,
    });

    if (ok) {
      alert("orden creada correctamente");
      const clientData = {
        name,
        street,
        reference: addressReference,
        neighbor: colony,
        postal_code: postalCode,
        city,
        state,
        country,
        phone,
        email,
      };
      await sendClientEmail(clientData, products);
      var emailStatus = await sendAfinazoneEmail(clientData, products);
      if (emailStatus.success) {
        alert("email enviado correctamente, revisar spam");
      } else {
        alert("no pudo ser enviado el email");
        console.log(emailStatus);
      }
    } else {
      alert(
        "orden pagada pero no creada, contactar a soporte para envío correcto de su orden"
      );
    }
  };

  const validateFields = () => {
    if (phone.length < 10) {
      alert("telefono incorrecto");
      return;
    } else if (!name) {
      alert("indica algún nombre");
      return;
    } else if (!street) {
      alert("indica alguna calle");
      return;
    } else if (!postalCode) {
      alert("indica algún código postal");
      return;
    } else if (!colony) {
      alert("indica alguna colonia");
      return;
    } else if (!city) {
      alert("indica alguna ciudad");
      return;
    } else if (!state) {
      alert("indica algún estado");
      return;
    } else if (!country) {
      alert("indica algún país");
      return;
    } else if (!addressReference) {
      alert("indica alguna referencia de domicilio");
      return;
    } else if (!email || !validateEmail(email)) {
      alert("indica un email válido");
      return;
    }
    setPay(2);
  };

  if (pay === 0) {
    return (
      <>
        <Box sx={{ display: { xs: "none", sm: "block" } }} style={container}>
          Carrito de compra
          <hr />
          <Box
            style={sections}
            sx={{ flexDirection: { xs: "column", md: "row" } }}
          >
            <Box
              sx={{ width: { xs: productSectionMobile, md: productSection } }}
            >
              <Products
                items={items}
                updateItems={(newItems) => setItems(newItems)}
              />
            </Box>
            <Box
              sx={{
                width: {
                  xs: shoppingCartSectionMobile,
                  md: shoppingCartSection,
                },
              }}
            >
              <ShoppingCart
                allAmounts={allAmounts}
                price={price}
                pay={pay}
                updatePay={(newPay) => {
                  if (items.length !== 0) setPay(newPay);
                  else alert("Escoge algunos productos");
                }}
              />
            </Box>
          </Box>
        </Box>
        <Box
          sx={{ display: { xs: "block", sm: "none" } }}
          style={containerMobile}
        >
          Carrito de compra
          <hr />
          <div style={sectionsMobile}>
            <div style={{ width: "100%" }}>
              <Products
                items={items}
                updateItems={(newItems) => setItems(newItems)}
              />
            </div>
            <Box
              sx={{
                width: {
                  xs: shoppingCartSectionMobile,
                  md: shoppingCartSection,
                },
              }}
            >
              <ShoppingCartMobile
                allAmounts={allAmounts}
                price={price}
                pay={pay}
                updatePay={(newPay) => {
                  if (items.length !== 0) setPay(newPay);
                  else alert("Escoge algunos productos");
                }}
              />
            </Box>
          </div>
        </Box>
      </>
    );
  } else if (pay === 1) {
    return (
      <Box
        sx={{
          paddingLeft: "10%",
          paddingRight: "10%",
          paddingTop: { xs: "30px", sm: "100px" },
          fontSize: { xs: "22px", sm: "40px" },
          textAlign: "center",
          fontWeight: { xs: "700", sm: "500" },
          fontFamily: "Nunito Sans, sans-serif",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          position: "relative",
        }}
      >
        <Box
          component={ArrowBackIcon}
          sx={{
            fontSize: { xs: "25px", sm: "40px" },
            fontWeight: "900",
            cursor: "pointer",
            position: "absolute",
            left: "10%", // Posición relativa al contenedor
            top: { xs: "32px", sm: "110px" },
          }}
          onClick={() => setPay(0)}
        />
        <Box
          sx={{
            textAlign: "center",
            marginBottom: "10px",
            fontFamily: "inherit",
            fontSize: "inherit",
          }}
        >
          Información de envío
        </Box>
        <hr style={{ width: "100%", borderColor: "#ccc" }} />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            textAlign: "left",
            width: "100%", // Asegura que el formulario ocupe el 100% del ancho disponible
          }}
        >
          <p style={labelStyle}> Nombre </p>
          <TextField
            id="referencia-name"
            placeholder="Nombre de quién recibe el producto"
            variant="filled"
            fullWidth
            style={fieldStyle}
            value={name}
            onChange={(e) => setName(e.target.value)}
            autoComplete="name"
          />

          <p style={labelStyle}> Correo Electrónico </p>
          <TextField
            id="referencia-email"
            placeholder="Email"
            variant="filled"
            fullWidth
            style={fieldStyle}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />

          <p style={labelStyle}> Número de Teléfono</p>
          <TextField
            id="referencia-phone"
            placeholder="Número telefónico"
            variant="filled"
            fullWidth
            style={fieldStyle}
            value={phone}
            onChange={(e) => validatePhone(e.target.value)}
            name="phone"
            autoComplete="phone"
          />

          <p style={labelStyle}> Calle y número </p>
          <TextField
            id="referencia-street"
            placeholder="EJ: PASEO DE LA REFORMA #163B"
            variant="filled"
            fullWidth
            style={fieldStyle}
            value={street}
            onChange={(e) => setStreet(e.target.value)}
          />

          <p style={labelStyle}> Código postal </p>
          <TextField
            id="referencia-postalCode"
            placeholder="EJ: 066600"
            variant="filled"
            fullWidth
            style={fieldStyle}
            value={postalCode}
            onChange={(e) => setPostalCode(e.target.value)}
          />

          <p style={labelStyle}> Colonia </p>
          <TextField
            id="referencia-colony"
            placeholder="EJ: LINDAVISTA"
            variant="filled"
            fullWidth
            style={fieldStyle}
            value={colony}
            onChange={(e) => setColony(e.target.value)}
            name="address2"
            autoComplete="address-level2"
          />

          <p style={labelStyle}> Ciudad </p>
          <TextField
            id="referencia-city"
            placeholder="EJ: Monterrey"
            variant="filled"
            fullWidth
            style={fieldStyle}
            value={city}
            onChange={(e) => setCity(e.target.value)}
          />

          <p style={labelStyle}> Estado </p>
          <TextField
            id="referencia-state"
            placeholder="EJ: Nuevo León"
            variant="filled"
            fullWidth
            style={fieldStyle}
            value={state}
            onChange={(e) => setState(e.target.value)}
          />

          <p style={labelStyle}> País </p>
          <TextField
            id="country"
            name="country"
            placeholder="EJ: México"
            variant="filled"
            fullWidth
            style={fieldStyle}
            value={country}
            onChange={(e) => setCountry(e.target.value)}
            autoComplete="country"
          />

          <p style={labelStyle}> Referencia de domicilio</p>
          <TextField
            id="referencia-domicilio"
            placeholder="Referencia del domicilio a ingresar"
            multiline
            variant="filled"
            fullWidth
            rows={4}
            onChange={(e) => setAddressReference(e.target.value)}
            style={fieldStyle}
            value={addressReference}
          />

          <Box
            sx={{
              display: "flex",
              justifyContent: { xs: "center", sm: "flex-start" },
            }}
          >
            <Button
              style={{
                width: window.innerWidth < 600 ? "150px" : "300px",
                marginTop: window.innerWidth < 600 ? "20px" : "30px",
                marginBottom: window.innerWidth < 600 ? "20px" : "30px",
                height: window.innerWidth < 600 ? "30px" : "50px",
              }}
              color="primary"
              variant="contained"
              onClick={() => {
                validateFields();
              }}
            >
              <Typography
                sx={{
                  fontSize: { xs: "11px", sm: "20px" },
                  fontWeight: "900",
                  fontFamily: "Nunito Sans, sans-serif",
                  textTransform: "none",
                }}
              >
                PROCEDER AL PAGO
              </Typography>
            </Button>
          </Box>
        </div>
      </Box>
    );
  } else {
    return (
      <Box
        sx={{
          paddingLeft: "10%",
          paddingRight: "10%",
          paddingTop: { xs: "30px", sm: "100px" },
          fontSize: { xs: "22px", sm: "40px" },
          textAlign: "center",
          fontWeight: { xs: "700", sm: "500" },
          fontFamily: "Nunito Sans, sans-serif",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          position: "relative",
        }}
      >
        <Box
          component={ArrowBackIcon}
          sx={{
            fontSize: { xs: "25px", sm: "40px" },
            fontWeight: "900",
            cursor: "pointer",
            position: "absolute",
            left: "10%", // Posición relativa al contenedor
            top: { xs: "32px", sm: "110px" },
          }}
          onClick={() => setPay(1)}
        />
        <Box
          sx={{
            textAlign: "center",
            marginBottom: "10px",
            fontFamily: "inherit",
            fontSize: "inherit",
          }}
        >
          Finalizar Compra
        </Box>
        <hr style={{ width: "100%", borderColor: "#ccc" }} />
        <Box component="div" sx={{ display: { xs: "none", md: "flex" } }}>
          <div style={paybutton}>
            <Paypal items={items} createSellOrder={createSellOrder} />
          </div>
          <div style={payinfo}>
            <PayInfo items={items} price={price} />
          </div>
        </Box>
        <Box component="div" sx={{ display: { xs: "block", md: "none" } }}>
          <div style={payinfoMobile}>
            <PayInfo items={items} price={price} />
          </div>
          <div style={paybuttonMobile}>
            <Paypal items={items} createSellOrder={createSellOrder} />
          </div>
        </Box>
      </Box>
    );
  }
}
