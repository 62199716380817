/* Pictures */
import imageBroken from "assets/img/image.svg";

/* Material UI */
import Button from "@material-ui/core/Button";

/* Icons */
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";

/* Routing */
import { Link } from "react-router-dom";
import { convertToShoppingCartElement } from "helpers/helpers";

import Box from "@mui/material/Box";

import CurrencyFormat from "react-currency-format";
import { Divider, Typography } from "@mui/material";

export default function CatalogueMobile(props) {
  if (props.catalogue && !props.catalogue.length) {
    return noProducts();
  }

  if (props.type === "KAT") return showKat(props.catalogue, props.type);
  if (props.type === "KAM") return showKam(props.catalogue, props.type);
  if (props.type === "Accs") return showAccs(props.catalogue, props.type);
  if (props.type === "Bj") return showBj(props.catalogue, props.type);
  if (props.type === "CaAc") return showCaAc(props.catalogue, props.type);
  if (props.type === "Flt") return showFlt(props.catalogue, props.type);
  if (props.type === "Ac") return showAc(props.catalogue, props.type);
  if (props.type === "Enf") return showEnf(props.catalogue, props.type);
  if (props.type === "Cart") return showCart(props.catalogue, props.type);
  else return null;
}

function noProducts() {
  return (
    <div
      style={{
        color: "#7F7F7F",
        fontSize: "20px",
        fontWeight: "700",
        paddingBottom: "30px",
        paddingTop: "30px",
      }}
    >
      Selecciona otra marca y modelo
    </div>
  );
}

const styleLink = {
  display: "flex",
  textDecoration: "none",
  color: "black",
  cursor: "pointer",
  width: "100%",
};

function showBj(catalogue, type) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        paddingTop: "35px",
      }}
    >
      {catalogue !== null &&
        catalogue.map((item, idx) => {
          return (
            <div
              key={idx}
              style={{
                width: "100%",
                paddingBottom: "50px",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Link
                to={`/Producto?product=${type}&id=${item.id}`}
                target="_blank"
                style={styleLink}
              >
                <img
                  src={item.url !== null ? item.url : imageBroken}
                  alt="car"
                  style={{
                    cursor: "pointer",
                    width: "200px",
                    height: "200px",
                  }}
                />
                {/* Description */}
                <div
                  style={{
                    alignSelf: "flex-start",
                    marginTop: "30px",
                    marginLeft: "30px",
                    fontSize: "30px",
                    fontWeight: "bold",
                    textAlign: "left",
                  }}
                >
                  {item.name}
                </div>
                <Typography variant="body1" align="left">
                  <Box component="span" color="error.main">
                    envío GRATIS
                  </Box>
                  <Box component="span">
                    &nbsp;apartar de $549 en tu carrito
                  </Box>
                </Typography>
              </Link>

              {/* Capacity */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                  marginTop: "30px",
                  fontSize: "30px",
                  fontWeight: "bold",
                  textAlign: "left",
                  width: "100%",
                }}
              >
                <div style={{ marginBottom: "20px" }}>
                  {item.disableOil && (
                    <>
                      ${" "}
                      <CurrencyFormat
                        value={item.price}
                        thousandSeparator={true}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        displayType={"text"}
                      />
                      <Link
                        to={`/Producto?product=${type}&id=${item.id}`}
                        target="_blank"
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          style={{ marginLeft: "30px" }}
                        >
                          Ver producto
                        </Button>
                      </Link>
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ marginLeft: "30px" }}
                        onClick={() => addToShoppingCart(item, "Bj", null)}
                      >
                        <AddShoppingCartIcon />
                      </Button>
                    </>
                  )}
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
}

function showKat(catalogue, type) {
  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap", // Permite que los elementos se envuelvan en varias filas
      }}
    >
      {catalogue !== null &&
        catalogue.map((item, idx) => {
          return (
            <div
              key={idx}
              style={{
                width: "45%", // Cambia el ancho para que quepan dos elementos por fila
                paddingBottom: "50px",
                display: "flex",
                flexDirection: "column", // Cambia a columna si es necesario
                padding: "3px",
                borderBottom: "4px solid #e1e1e1", // Borde inferior
                borderRight: idx % 2 === 0 ? "4px solid #e1e1e1" : "none", // Borde izquierdo si es par
                justifyContent: "center", // Center content vertically
                alignItems: "center", // Center content horizontally
              }}
            >
              <Box
                component="div"
                sx={{
                  flexDirection: { xs: "row", md: "row" },
                }}
                style={{
                  display: "flex",
                  flexGrow: 1, // Permite que el contenido ocupe el espacio disponible
                  justifyContent: "center", // Center inner content horizontally
                  alignItems: "center", // Center inner content vertically
                  flexDirection: "column", // Use column layout for inner Box
                }}
              >
                <Box
                  component="div"
                  to={`/Producto?product=${type}&id=${item.id}`}
                  target="_blank"
                  style={styleLink}
                  sx={{
                    flexDirection: { xs: "column" },
                  }}
                >
                  <Link
                    to={`/Producto?product=${type}&id=${item.id}`}
                    target="_blank"
                  >
                    <Box
                      component="img"
                      src={item.url !== null ? item.url : imageBroken}
                      alt="car"
                      style={{
                        cursor: "pointer",
                        marginRight: "15px",
                        width: "100px",
                        height: "100px",
                        alignSelf: "center", // Center the image horizontally
                      }}
                    />
                  </Link>
                  {/* Description */}
                  <div style={{ textAlign: "center" }}>
                    {" "}
                    {/* Center text content */}
                    <Box
                      component={Link}
                      to={`/Producto?product=${type}&id=${item.id}`}
                      target="_blank"
                      sx={{
                        fontSize: { xs: "10px" },
                      }}
                      style={{
                        alignSelf: "center",
                        fontWeight: "bold",
                        textDecoration: "none",
                        color: "black",
                        maxWidth: "100%",
                      }}
                    >
                      <div
                        style={{
                          textAlign: "center", // Center the title text
                          fontWeight: "800",
                          fontFamily: "Nunito Sans, sans-serif",
                        }}
                      >
                        {item.name}
                      </div>
                      <Typography variant="body1" align="center" fontSize="9px">
                        {" "}
                        {/* Change to align center */}
                        <Box component="span" color="error.main">
                          envío GRATIS
                        </Box>
                        <Box component="span">
                          &nbsp;apartar de $549 en tu carrito
                        </Box>
                      </Typography>
                    </Box>
                    <Box
                      component="div"
                      sx={{
                        display: { xs: "flex", md: "none" },
                      }}
                      style={{
                        flexDirection: "column",
                        fontSize: "10px",
                        fontWeight: "bold",
                        textAlign: "center", // Center text
                        width: "100%",
                      }}
                    >
                      <div
                        style={{
                          alignSelf: "center", // Center this section
                          marginLeft: "5px",
                        }}
                      >
                        {item.disableOil && (
                          <div
                            style={{
                              marginTop: "15px",
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center", // Center align the items
                            }}
                          >
                            <div style={{ fontSize: "15px" }}>
                              <CurrencyFormat
                                prefix={"$ "} // Agrega el símbolo de $
                                value={item.price}
                                thousandSeparator={true}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                displayType={"text"}
                              />
                            </div>
                            <Link
                              to={`/Producto?product=${type}&id=${item.id}`}
                              target="_blank"
                            >
                              <Button
                                variant="contained"
                                color="primary"
                                style={{
                                  margin: "10px",
                                  padding: "4px 8px",
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontSize: "10px",
                                    fontWeight: "900",
                                    fontFamily: "Nunito Sans, sans-serif",
                                    textTransform: "none",
                                  }}
                                >
                                  Ver producto
                                </Typography>
                              </Button>
                            </Link>
                          </div>
                        )}
                        {!item.disableOil && (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center", // Center items
                            }}
                          >
                            {item.liters.map((current, idx) => {
                              return (
                                <div
                                  key={idx} // Added key for mapping
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center", // Center vertically
                                    alignItems: "center", // Center horizontally
                                    marginTop: "10px",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center", // Center items horizontally
                                    }}
                                  >
                                    <div
                                      style={{
                                        marginRight: "5px",
                                        fontFamily: "Nunito Sans, sans-serif",
                                      }}
                                    >
                                      <CurrencyFormat
                                        prefix={"$ "} // Agrega el símbolo de $
                                        value={current.price}
                                        thousandSeparator={true}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        displayType={"text"}
                                        style={{
                                          fontSize: "12px",
                                          fontWeight: "900",
                                          fontFamily: "Nunito Sans, sans-serif",
                                        }}
                                      />
                                    </div>
                                    <div>{current.liters} Litros</div>
                                  </div>
                                  <Divider style={{ width: "100%" }} />
                                </div>
                              );
                            })}
                            <Link
                              to={`/Producto?product=${type}&id=${item.id}`}
                              target="_blank"
                            >
                              <Button
                                variant="contained"
                                color="primary"
                                style={{
                                  margin: "10px",
                                  padding: "4px 8px",
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontSize: "10px",
                                    fontWeight: "900",
                                    fontFamily: "Nunito Sans, sans-serif",
                                    textTransform: "none",
                                  }}
                                >
                                  Ver producto
                                </Typography>
                              </Button>
                            </Link>
                          </div>
                        )}
                      </div>
                    </Box>
                  </div>
                </Box>
              </Box>
            </div>
          );
        })}
    </div>
  );
}

function showAccs(catalogue, type) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        paddingTop: "35px",
      }}
    >
      {catalogue !== null &&
        catalogue.map((item, idx) => {
          return (
            <div
              key={idx}
              style={{
                width: "100%",
                paddingBottom: "50px",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Link
                to={`/Producto?product=${type}&id=${item.id}`}
                target="_blank"
                style={styleLink}
              >
                <img
                  src={item.url !== null ? item.url : imageBroken}
                  alt="car"
                  style={{
                    cursor: "pointer",
                    width: "200px",
                    height: "200px",
                  }}
                />
                {/* Description */}
                <div
                  style={{
                    alignSelf: "flex-start",
                    marginTop: "30px",
                    marginLeft: "30px",
                    fontSize: "30px",
                    fontWeight: "bold",
                    textAlign: "left",
                  }}
                >
                  {item.name}
                </div>
                <Typography variant="body1" align="left">
                  <Box component="span" color="error.main">
                    envío GRATIS
                  </Box>
                  <Box component="span">
                    &nbsp;apartar de $549 en tu carrito
                  </Box>
                </Typography>
              </Link>

              {/* Capacity */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                  marginTop: "30px",
                  fontSize: "30px",
                  fontWeight: "bold",
                  textAlign: "left",
                  width: "100%",
                }}
              >
                <div style={{ marginBottom: "20px" }}>
                  {item.disableOil && (
                    <>
                      ${" "}
                      <CurrencyFormat
                        value={item.price}
                        thousandSeparator={true}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        displayType={"text"}
                      />
                      <Link
                        to={`/Producto?product=${type}&id=${item.id}`}
                        target="_blank"
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          style={{ marginLeft: "30px" }}
                        >
                          Ver producto
                        </Button>
                      </Link>
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ marginLeft: "30px" }}
                        onClick={() => addToShoppingCart(item, "Accs", null)}
                      >
                        <AddShoppingCartIcon />
                      </Button>
                    </>
                  )}
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
}

function showKam(catalogue, type) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        paddingTop: "35px",
      }}
    >
      {catalogue !== null &&
        catalogue.map((item, idx) => {
          return (
            <div
              key={idx}
              style={{
                width: "100%",
                paddingBottom: "50px",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Link
                to={`/Producto?product=${type}&id=${item.id}`}
                target="_blank"
                style={styleLink}
              >
                <img
                  src={item.url !== null ? item.url : imageBroken}
                  alt="car"
                  style={{
                    cursor: "pointer",
                    width: "200px",
                    height: "200px",
                  }}
                />
                {/* Description */}
                <div
                  style={{
                    alignSelf: "flex-start",
                    marginTop: "30px",
                    marginLeft: "30px",
                    fontSize: "30px",
                    fontWeight: "bold",
                    textAlign: "left",
                  }}
                >
                  {item.name}
                </div>
                <Typography variant="body1" align="left">
                  <Box component="span" color="error.main">
                    envío GRATIS
                  </Box>
                  <Box component="span">
                    &nbsp;apartar de $549 en tu carrito
                  </Box>
                </Typography>
              </Link>

              {/* Capacity */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                  marginTop: "30px",
                  fontSize: "30px",
                  fontWeight: "bold",
                  textAlign: "left",
                  width: "100%",
                }}
              >
                <div style={{ marginBottom: "20px" }}>
                  {item.disableOil && (
                    <>
                      ${" "}
                      <CurrencyFormat
                        value={item.price}
                        thousandSeparator={true}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        displayType={"text"}
                      />
                      <Link
                        to={`/Producto?product=${type}&id=${item.id}`}
                        target="_blank"
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          style={{ marginLeft: "30px" }}
                        >
                          Ver producto
                        </Button>
                      </Link>
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ marginLeft: "30px" }}
                        onClick={() => addToShoppingCart(item, "KAM", null)}
                      >
                        <AddShoppingCartIcon />
                      </Button>
                    </>
                  )}
                  {!item.disableOil &&
                    item.liters.map((current, idx) => {
                      return (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            margin: "5px",
                          }}
                        >
                          {current.liters} litros | ${" "}
                          <CurrencyFormat
                            value={current.price}
                            thousandSeparator={true}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            displayType={"text"}
                          />
                          <Button
                            variant="contained"
                            color="primary"
                            style={{ marginLeft: "30px" }}
                            onClick={() =>
                              addToShoppingCart(
                                { ...item, price: current.price },
                                "KAM",
                                current.liters
                              )
                            }
                          >
                            <AddShoppingCartIcon />
                          </Button>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
}

function showCaAc(catalogue, type) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        paddingTop: "35px",
      }}
    >
      {catalogue !== null &&
        catalogue.map((item, idx) => {
          return (
            <div
              key={idx}
              style={{
                width: "100%",
                paddingBottom: "50px",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Link
                to={`/Producto?product=${type}&id=${item.id}`}
                target="_blank"
                style={styleLink}
              >
                <img
                  src={item.url !== null ? item.url : imageBroken}
                  alt="car"
                  style={{
                    cursor: "pointer",
                    width: "200px",
                    height: "200px",
                  }}
                />
                {/* Description */}
                <div
                  style={{
                    alignSelf: "flex-start",
                    marginTop: "30px",
                    marginLeft: "30px",
                    fontSize: "30px",
                    fontWeight: "bold",
                    textAlign: "left",
                  }}
                >
                  {item.name}
                </div>
                <Typography variant="body1" align="left">
                  <Box component="span" color="error.main">
                    envío GRATIS
                  </Box>
                  <Box component="span">
                    &nbsp;apartar de $549 en tu carrito
                  </Box>
                </Typography>
              </Link>

              {/* Capacity */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                  marginTop: "30px",
                  fontSize: "30px",
                  fontWeight: "bold",
                  textAlign: "left",
                  width: "100%",
                }}
              >
                <div style={{ marginBottom: "20px" }}>
                  {item.disableOil && (
                    <>
                      ${" "}
                      <CurrencyFormat
                        value={item.price}
                        thousandSeparator={true}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        displayType={"text"}
                      />
                      <Link
                        to={`/Producto?product=${type}&id=${item.id}`}
                        target="_blank"
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          style={{ marginLeft: "30px" }}
                        >
                          Ver producto
                        </Button>
                      </Link>
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ marginLeft: "30px" }}
                        onClick={() => addToShoppingCart(item, "CaAc", null)}
                      >
                        <AddShoppingCartIcon />
                      </Button>
                    </>
                  )}
                  {!item.disableOil &&
                    item.liters.map((current, idx) => {
                      return (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            margin: "5px",
                          }}
                        >
                          {current.liters} litros | ${" "}
                          <CurrencyFormat
                            value={current.price}
                            thousandSeparator={true}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            displayType={"text"}
                          />
                          <Button
                            variant="contained"
                            color="primary"
                            style={{ marginLeft: "30px" }}
                            onClick={() =>
                              addToShoppingCart(
                                { ...item, price: current.price },
                                "CaAc",
                                current.liters
                              )
                            }
                          >
                            <AddShoppingCartIcon />
                          </Button>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
}

function showFlt(catalogue, type) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        paddingTop: "35px",
      }}
    >
      {catalogue !== null &&
        catalogue.map((item, idx) => {
          return (
            <div
              key={idx}
              style={{
                width: "100%",
                paddingBottom: "50px",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Link
                to={`/Producto?product=${type}&id=${item.id}`}
                target="_blank"
                style={styleLink}
              >
                <img
                  src={item.url !== null ? item.url : imageBroken}
                  alt="car"
                  style={{
                    cursor: "pointer",
                    width: "200px",
                    height: "200px",
                  }}
                />
                {/* Description */}
                <div
                  style={{
                    alignSelf: "flex-start",
                    marginTop: "30px",
                    marginLeft: "30px",
                    fontSize: "30px",
                    fontWeight: "bold",
                    textAlign: "left",
                  }}
                >
                  {item.name}
                </div>
                <Typography variant="body1" align="left">
                  <Box component="span" color="error.main">
                    envío GRATIS
                  </Box>
                  <Box component="span">
                    &nbsp;apartar de $549 en tu carrito
                  </Box>
                </Typography>
              </Link>

              {/* Capacity */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                  marginTop: "30px",
                  fontSize: "30px",
                  fontWeight: "bold",
                  textAlign: "left",
                  width: "100%",
                }}
              >
                <div style={{ marginBottom: "20px" }}>
                  {item.disableOil && (
                    <>
                      ${" "}
                      <CurrencyFormat
                        value={item.price}
                        thousandSeparator={true}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        displayType={"text"}
                      />
                      <Link
                        to={`/Producto?product=${type}&id=${item.id}`}
                        target="_blank"
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          style={{ marginLeft: "30px" }}
                        >
                          Ver producto
                        </Button>
                      </Link>
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ marginLeft: "30px" }}
                        onClick={() => addToShoppingCart(item, "Flt", null)}
                      >
                        <AddShoppingCartIcon />
                      </Button>
                    </>
                  )}
                  {!item.disableOil &&
                    item.liters.map((current, idx) => {
                      return (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            margin: "5px",
                          }}
                        >
                          {current.liters} litros | ${" "}
                          <CurrencyFormat
                            value={current.price}
                            thousandSeparator={true}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            displayType={"text"}
                          />
                          <Button
                            variant="contained"
                            color="primary"
                            style={{ marginLeft: "30px" }}
                            onClick={() =>
                              addToShoppingCart(
                                { ...item, price: current.price },
                                "Flt",
                                current.liters
                              )
                            }
                          >
                            <AddShoppingCartIcon />
                          </Button>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
}

function showAc(catalogue, type) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        paddingTop: "35px",
      }}
    >
      {catalogue !== null &&
        catalogue.map((item, idx) => {
          return (
            <div
              key={idx}
              style={{
                width: "100%",
                paddingBottom: "50px",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Link
                to={`/Producto?product=${type}&id=${item.id}`}
                target="_blank"
                style={styleLink}
              >
                <img
                  src={item.url !== null ? item.url : imageBroken}
                  alt="car"
                  style={{
                    cursor: "pointer",
                    width: "200px",
                    height: "200px",
                  }}
                />
                {/* Description */}
                <div
                  style={{
                    alignSelf: "flex-start",
                    marginTop: "30px",
                    marginLeft: "30px",
                    fontSize: "30px",
                    fontWeight: "bold",
                    textAlign: "left",
                  }}
                >
                  {item.name}
                </div>
                <Typography variant="body1" align="left">
                  <Box component="span" color="error.main">
                    envío GRATIS
                  </Box>
                  <Box component="span">
                    &nbsp;apartar de $549 en tu carrito
                  </Box>
                </Typography>
              </Link>

              {/* Capacity */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                  marginTop: "30px",
                  fontSize: "30px",
                  fontWeight: "bold",
                  textAlign: "left",
                  width: "100%",
                }}
              >
                <div style={{ marginBottom: "20px" }}>
                  {item.disableOil && (
                    <>
                      ${" "}
                      <CurrencyFormat
                        value={item.price}
                        thousandSeparator={true}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        displayType={"text"}
                      />
                      <Link
                        to={`/Producto?product=${type}&id=${item.id}`}
                        target="_blank"
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          style={{ marginLeft: "30px" }}
                        >
                          Ver producto
                        </Button>
                      </Link>
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ marginLeft: "30px" }}
                        onClick={() => addToShoppingCart(item, "Ac", null)}
                      >
                        <AddShoppingCartIcon />
                      </Button>
                    </>
                  )}
                  {!item.disableOil &&
                    item.liters.map((current, idx) => {
                      return (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            margin: "5px",
                          }}
                        >
                          {current.liters} litros | ${" "}
                          <CurrencyFormat
                            value={current.price}
                            thousandSeparator={true}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            displayType={"text"}
                          />
                          <Button
                            variant="contained"
                            color="primary"
                            style={{ marginLeft: "30px" }}
                            onClick={() =>
                              addToShoppingCart(
                                { ...item, price: current.price },
                                "Ac",
                                current.liters
                              )
                            }
                          >
                            <AddShoppingCartIcon />
                          </Button>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
}

function showEnf(catalogue, type) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        paddingTop: "35px",
      }}
    >
      {catalogue !== null &&
        catalogue.map((item, idx) => {
          return (
            <div
              key={idx}
              style={{
                width: "100%",
                paddingBottom: "50px",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Link
                to={`/Producto?product=${type}&id=${item.id}`}
                target="_blank"
                style={styleLink}
              >
                <img
                  src={item.url !== null ? item.url : imageBroken}
                  alt="car"
                  style={{
                    cursor: "pointer",
                    width: "200px",
                    height: "200px",
                  }}
                />
                {/* Description */}
                <div
                  style={{
                    alignSelf: "flex-start",
                    marginTop: "30px",
                    marginLeft: "30px",
                    fontSize: "30px",
                    fontWeight: "bold",
                    textAlign: "left",
                  }}
                >
                  {item.name}
                </div>
                <Typography variant="body1" align="left">
                  <Box component="span" color="error.main">
                    envío GRATIS
                  </Box>
                  <Box component="span">
                    &nbsp;apartar de $549 en tu carrito
                  </Box>
                </Typography>
              </Link>

              {/* Capacity */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                  marginTop: "30px",
                  fontSize: "30px",
                  fontWeight: "bold",
                  textAlign: "left",
                  width: "100%",
                }}
              >
                <div style={{ marginBottom: "20px" }}>
                  {item.disableOil && (
                    <>
                      ${" "}
                      <CurrencyFormat
                        value={item.price}
                        thousandSeparator={true}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        displayType={"text"}
                      />
                      <Link
                        to={`/Producto?product=${type}&id=${item.id}`}
                        target="_blank"
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          style={{ marginLeft: "30px" }}
                        >
                          Ver producto
                        </Button>
                      </Link>
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ marginLeft: "30px" }}
                        onClick={() => addToShoppingCart(item, "Enf", null)}
                      >
                        <AddShoppingCartIcon />
                      </Button>
                    </>
                  )}
                  {!item.disableOil &&
                    item.liters.map((current, idx) => {
                      return (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            margin: "5px",
                          }}
                        >
                          {current.liters} litros | ${" "}
                          <CurrencyFormat
                            value={current.price}
                            thousandSeparator={true}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            displayType={"text"}
                          />
                          <Button
                            variant="contained"
                            color="primary"
                            style={{ marginLeft: "30px" }}
                            onClick={() =>
                              addToShoppingCart(
                                { ...item, price: current.price },
                                "Enf",
                                current.liters
                              )
                            }
                          >
                            <AddShoppingCartIcon />
                          </Button>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
}

function showCart(catalogue, type) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        paddingTop: "35px",
      }}
    >
      {catalogue !== null &&
        catalogue.map((item, idx) => {
          return (
            <div
              key={idx}
              style={{
                width: "100%",
                paddingBottom: "50px",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Link
                to={`/Producto?product=${type}&id=${item.id}`}
                target="_blank"
                style={styleLink}
              >
                <img
                  src={item.url !== null ? item.url : imageBroken}
                  alt="car"
                  style={{
                    cursor: "pointer",
                    width: "200px",
                    height: "200px",
                  }}
                />
                {/* Description */}
                <div
                  style={{
                    alignSelf: "flex-start",
                    marginTop: "30px",
                    marginLeft: "30px",
                    fontSize: "30px",
                    fontWeight: "bold",
                    textAlign: "left",
                  }}
                >
                  {item.name}
                </div>
                <Typography variant="body1" align="left">
                  <Box component="span" color="error.main">
                    envío GRATIS
                  </Box>
                  <Box component="span">
                    &nbsp;apartar de $549 en tu carrito
                  </Box>
                </Typography>
              </Link>

              {/* Capacity */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                  marginTop: "30px",
                  fontSize: "30px",
                  fontWeight: "bold",
                  textAlign: "left",
                  width: "100%",
                }}
              >
                <div style={{ marginBottom: "20px" }}>
                  {item.disableOil && (
                    <>
                      ${" "}
                      <CurrencyFormat
                        value={item.price}
                        thousandSeparator={true}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        displayType={"text"}
                      />
                      <Link
                        to={`/Producto?product=${type}&id=${item.id}`}
                        target="_blank"
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          style={{ marginLeft: "30px" }}
                        >
                          Ver producto
                        </Button>
                      </Link>
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ marginLeft: "30px" }}
                        onClick={() => addToShoppingCart(item, "Cart", null)}
                      >
                        <AddShoppingCartIcon />
                      </Button>
                    </>
                  )}
                  {!item.disableOil &&
                    item.liters.map((current, idx) => {
                      return (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            margin: "5px",
                          }}
                        >
                          {current.liters} litros | ${" "}
                          <CurrencyFormat
                            value={current.price}
                            thousandSeparator={true}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            displayType={"text"}
                          />
                          <Button
                            variant="contained"
                            color="primary"
                            style={{ marginLeft: "30px" }}
                            onClick={() =>
                              addToShoppingCart(
                                { ...item, price: current.price },
                                "Cart",
                                current.liters
                              )
                            }
                          >
                            <AddShoppingCartIcon />
                          </Button>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
}

function addToShoppingCart(data, type, description) {
  function getItem() {
    var newItem = {
      id: data.id,
      type: type,
      description: description ? description + " litros" : "",
      price: data.price,
      amount: 1,
      name: data.name,
    };
    newItem = JSON.stringify(newItem);
    return newItem;
  }

  const shoppingCart = convertToShoppingCartElement();
  if (shoppingCart) {
    if (
      !shoppingCart.filter((item) => {
        return (
          item.id === data.id &&
          (description ? item.description === description + " litros" : true)
        );
      }).length
    ) {
      const newItem = getItem();
      let newShoppingCart = [
        ...shoppingCart.map((item) => JSON.stringify(item)),
        newItem,
      ];
      newShoppingCart = newShoppingCart.join("+|+");
      localStorage.setItem("carrito", newShoppingCart);
      alert("Se agregó al carrito");
    } else alert("Este elemento ya está en tu carrito");
  } else {
    const newItem = getItem();
    let newShoppingCart = [newItem];
    newShoppingCart = newShoppingCart.join("+|+");
    localStorage.setItem("carrito", newShoppingCart);
    alert("Se agregó al carrito");
  }
}
