import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Button, Divider, Grid, useMediaQuery } from "@mui/material";
import "./Tips.css"; // Importa el archivo CSS de estilos

import { getAllPapers } from "utils/API/ArticulosAPI";
import { getPreviewImageUrlFromPaper } from "utils/API/Pictures";

export default function Tips() {
  const isXs = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const titleStyle = {
    fontFamily: "Nunito Sans, sans-serif",
    fontSize: isXs ? "30px" : "70px",
    fontWeight: "700",
    marginBottom: "10px",
  };

  const pageStyle = {
    padding: "10px",
    paddingLeft: "10px",
    paddingRight: "10px",
    fontSize: "100%",
  };

  const [papers, setPapers] = useState([]);

  useEffect(function () {
    async function go() {
      const papers = await getAllPapers();
      for (const paper of papers) {
        paper.url = await getPreviewImageUrlFromPaper(paper.id);
      }

      papers.sort((a, b) => a.order - b.order);
      setPapers(papers);
    }
    go();
  }, []);

  return (
    <div style={pageStyle}>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid
          container
          xs={10}
          md={8}
          direction="row"
          justifyContent="center"
          alignItems="center"
          style={titleStyle}
        >
          <h2 style={titleStyle}>TIPS PARA TU VEHÍCULO</h2>
          <Divider
            style={{
              backgroundColor: "black",
              height: "2px",
              bottom: "-5px",
              width: "100%",
              left: 0,
            }}
          />
        </Grid>
        <div className="papers-container">
          {papers.map((paper) => (
            <PreviewPaper key={paper.id} paper={paper} />
          ))}
        </div>
      </Grid>
    </div>
  );
}

function PreviewPaper({ paper }) {
  const history = useHistory();

  return (
    <div
      className="preview-paper"
      onClick={() => history.push(`/Paper?id=${paper.id}`)}
    >
      <img src={paper.url} alt="url" className="picture" />
      <div className="text-container">
        <div className="title">{paper.title}</div>
        <div className="description">{paper.description}</div>
        <Button variant="contained" className="view-button">
          Ver completo
        </Button>
      </div>
    </div>
  );
}
