/* Material UI */
import { Button } from "@material-ui/core";

import CurrencyFormat from "react-currency-format";

/* Icons */
import ship from "assets/img/ship.png";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";

const card = {
  padding: "15px",
  backgroundColor: "white",
  alignItems: "center",
  fontSize: "20px",
  fontWeight: "300",
  textAlign: "left",
};

const row = {
  display: "flex",
  justifyContent: "space-between",
  fontFamily: "Poppins, sans-serif",
  fontSize: "15px",
};

const rowMini = {
  display: "flex",
  justifyContent: "space-between",
  margin: "5px",
  width: "100%",
  fontFamily: "Poppins, sans-serif",
  fontSize: "13px",
};

const strongFont = {
  fontWeight: "600",
  fontSize: "14px",
  fontFamily: "Poppins, sans-serif",
};

export default function ShoppingCartMobile({ allAmounts, price, updatePay }) {
  return (
    <div style={{ margin: "15px" }}>
      <div style={card}>
        <div style={row} sx={{ fontSize: { xs: "15px", md: "20px" } }}>
          Precio del carrito
          <div>
            <CurrencyFormat
              value={price}
              thousandSeparator={true}
              decimalScale={2}
              fixedDecimalScale={true}
              displayType={"text"}
              prefix="$"
              style={strongFont}
            />{" "}
          </div>
        </div>

        <div style={rowMini} sx={{ fontSize: { xs: "12px", md: "15px" } }}>
          IVA incluido
        </div>
        <hr />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            fontFamily: "Poppins, sans-serif",
            fontWeight: "600",
            margin: "5px",
          }}
        >
          <div style={row}> Costo de envío </div>
          <div>
            {" "}
            {price >= 549 ? (
              <Box
                component="span"
                color="error.main"
                style={{
                  fontFamily: "Poppins, sans-serif",
                  fontWeight: "600",
                }}
              >
                ¡GRATIS!
              </Box>
            ) : (
              "$ 149.00"
            )}{" "}
          </div>
        </div>
        <div style={{ display: "flex", alignItems: "flex-end" }}>
          <div>
            <div style={rowMini}>Envío estándar</div>
            <div style={{ ...rowMini, fontSize: "10px" }}>
              Tiempo de entrega estimado es de 2 a 5 días hábiles según el
              domicilio de entrega
            </div>
          </div>
          <img src={ship} alt='img' style={{ height: "40px" }} />
        </div>

        <hr />

        <div style={row} sx={{ fontSize: { xs: "13px", md: "15px" } }}>
          <div style={strongFont}> Subtotal: </div>
          <div style={strongFont}>
            <CurrencyFormat
              value={
                parseFloat(price) +
                parseFloat(price >= 549 || parseInt(price) === 0 ? 0 : 149)
              }
              thousandSeparator={true}
              decimalScale={2}
              fixedDecimalScale={true}
              displayType={"text"}
              prefix="$"
              style={strongFont}
            />{" "}
          </div>
        </div>
      </div>

      <Button
        style={{
          width: window.innerWidth < 600 ? "150px" : "300px",
          marginTop: window.innerWidth < 600 ? "20px" : "30px",
          marginBottom: window.innerWidth < 600 ? "20px" : "30px",
          height: window.innerWidth < 600 ? "30px" : "50px",
        }}
        color="primary"
        variant="contained"
        onClick={() => {
          if (!allAmounts) alert("Selecciona alguna cantidad de productos");
          else updatePay(1);
        }}
      >
        <Typography
          sx={{
            fontSize: { xs: "11px", sm: "20px" },
            fontWeight: "900",
            fontFamily: "Nunito Sans, sans-serif",
            textTransform: "none",
          }}
        >
          PROCEDER AL PAGO
        </Typography>
      </Button>
    </div>
  );
}
