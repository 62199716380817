import { useEffect, useState } from "react";

/* Material UI */
import { Grid } from "@material-ui/core";

/* API */
import { getBrandsAPI } from "utils/API/Brands";

import { Divider } from "@mui/material";

export default function Models(props) {
  const [availableModels, setAvailableModels] = useState(null);
  const [notAvailableModels, setNotAvailableModels] = useState(null);
  const [allVariants, setAllVariants] = useState(null);

  useEffect(() => {
    const go = async () => {
      var brands = await getBrandsAPI();
      var models;
      var variants = {};
      brands.forEach((doc) => {
        const brand = { ...doc.data() };
        if (brand.name === props.brand) {
          models = brand.models;

          if (brand.variants !== undefined) {
            const variantsBrand = brand.variants;
            for (let idx = 0; idx < brand.models.length; idx++) {
              const variant = variantsBrand[idx];
              for (var current in variant) {
                let model = variant[current];
                if (variants[model] === undefined) {
                  variants[model] = [];
                }
                variants[model].push({ variant: current, active: false });
              }
            }
          }
        }
      });

      let checkModels = {};
      models.forEach((model) => {
        // doc.data() is never undefined for query doc snapshots
        checkModels[model] = false;
      });
      const products = props.products.filter(
        (item) => item.brand === props.brand
      );
      for (var product of products) {
        if (checkModels[product.model] !== undefined) {
          if (product.variant !== undefined) {
            let idx = variants[product.model].findIndex(
              // eslint-disable-next-line
              (item) => item.variant === product.variant
            );
            if (variants[product.model] && idx > -1) {
              variants[product.model][idx].active = true;
              checkModels[product.model] = true;
            }
          }
        }
      }

      let avModels = [];
      let notAvModels = [];

      for (var check of Object.keys(checkModels)) {
        checkModels[check] ? avModels.push(check) : notAvModels.push(check);
      }

      avModels.sort();
      notAvModels.sort();
      setAvailableModels(avModels);
      setNotAvailableModels(notAvModels);
      setAllVariants(variants);
    };
    if (props.products !== null && props.brand !== null) {
      go();
    }
  }, [props.products, props.brand]);

  return (
    <Grid container spacing={3}>
      {availableModels !== null &&
        availableModels.map((item, idx) => {
          return (
            <>
              <Grid key={`model-${idx}`} item sm={12}>
                <Divider
                  style={{
                    marginTop: "30px",
                    backgroundColor: "black",
                    height: "1px",
                    marginLeft: "5%",
                    marginRight: "5%",
                    marginBottom: "10px",
                  }}
                />
                <div
                  style={{
                    textAlign: "left",
                    marginLeft: "5%",
                    fontFamily: "Poppins, sans-serif",
                    fontWeight: "500",
                    fontSize: "25px",
                  }}
                >
                  {" "}
                  {item}{" "}
                </div>
                <Divider
                  style={{
                    marginTop: "10px",
                    backgroundColor: "black",
                    height: "1px",
                    marginLeft: "5%",
                    marginRight: "5%",
                  }}
                />
              </Grid>
              {allVariants !== null &&
                allVariants[item] !== undefined &&
                (() => {
                  const columns = [];
                  const l = Math.ceil(allVariants[item].length / 3);

                  allVariants[item]
                    .sort((a, b) => a.variant.localeCompare(b.variant))
                    .forEach((variant, idx) => {
                      const col = idx % 3;
                      const base =
                        col * l -
                        (allVariants[item].length % 3 === 1 && col === 2
                          ? 1
                          : 0);
                      const res = base + Math.floor(idx / 3);
                      columns.push(allVariants[item][res]);
                    });

                  return (
                    <>
                      {columns.map((variant) => (
                        <Grid item sm={4} style={{ color: "#bc0900" }}>
                          {variant.active && (
                            <div
                              style={{
                                textDecoration: "none",
                                fontSize: "22px",
                                fontWeight: "900",
                                color: "#0000EE",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                localStorage.setItem("model", item);
                                props.setOptionSection("product");
                                props.setModel(item);
                              }}
                            >
                              {" "}
                              {variant.variant}
                            </div>
                          )}
                          {!variant.active && (
                            <div
                              style={{
                                fontSize: "22px",
                                fontWeight: "900",
                              }}
                            >
                              {" "}
                              {variant.variant}{" "}
                            </div>
                          )}
                        </Grid>
                      ))}
                    </>
                  );
                })()}
            </>
          );
        })}
      <Grid item sm={12}>
        <div style={{ textAlign: "center" }}>
          <Divider
            style={{
              marginTop: "30px",
              backgroundColor: "black",
              height: "1px",
              width: "50%", // Los Divider solo ocupan el 50% del ancho del Grid
              margin: "0 auto", // Centrar los Divider
            }}
          />
          <div
            style={{
              fontFamily: "Poppins, sans-serif",
              fontWeight: "500",
              fontSize: "25px",
            }}
          >
            Modelos no disponibles
          </div>
          <Divider
            style={{
              marginTop: "10px",
              backgroundColor: "black",
              height: "1px",
              width: "50%", // Los Divider solo ocupan el 50% del ancho del Grid
              margin: "0 auto", // Centrar los Divider
            }}
          />
        </div>
      </Grid>

      {notAvailableModels !== null &&
        notAvailableModels.map((item, idx) => {
          return (
            <>
              <Grid key={`nv-model-${idx}`} item sm={12}>
                <Divider
                  style={{
                    marginTop: "30px",
                    backgroundColor: "black",
                    height: "1px",
                    marginLeft: "5%",
                    marginRight: "5%",
                    marginBottom: "10px",
                  }}
                />
                <div
                  style={{
                    textAlign: "left",
                    marginLeft: "5%",
                    fontFamily: "Poppins, sans-serif",
                    fontWeight: "500",
                    fontSize: "25px",
                  }}
                >
                  {" "}
                  {item}{" "}
                </div>
                <Divider
                  style={{
                    marginTop: "10px",
                    backgroundColor: "black",
                    height: "1px",
                    marginLeft: "5%",
                    marginRight: "5%",
                  }}
                />
              </Grid>
              {allVariants !== null &&
                allVariants[item] !== undefined &&
                (() => {
                  const columns = [];
                  const l = Math.ceil(allVariants[item].length / 3);

                  allVariants[item]
                    .sort((a, b) => a.variant.localeCompare(b.variant))
                    .forEach((variant, idx) => {
                      const col = idx % 3;
                      const base =
                        col * l -
                        (allVariants[item].length % 3 === 1 && col === 2
                          ? 1
                          : 0);
                      const res = base + Math.floor(idx / 3);
                      columns.push(allVariants[item][res]);
                    });

                  return (
                    <>
                      {columns.map((variant) => (
                        <Grid item sm={4} style={{ color: "#bc0900" }}>
                          {variant.active && (
                            <div
                              style={{
                                textDecoration: "none",
                                fontSize: "22px",
                                fontWeight: "900",
                                color: "#0000EE",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                localStorage.setItem("model", item);
                                props.setOptionSection("product");
                                props.setModel(item);
                              }}
                            >
                              {" "}
                              {variant.variant}
                            </div>
                          )}
                          {!variant.active && (
                            <div
                              style={{
                                fontSize: "22px",
                                fontWeight: "900",
                              }}
                            >
                              {" "}
                              {variant.variant}{" "}
                            </div>
                          )}
                        </Grid>
                      ))}
                    </>
                  );
                })()}
            </>
          );
        })}
    </Grid>
  );
}
