import React, { useState, useEffect } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "./Paper.css";
import { useQuery } from "helpers/helpers";
import { getPaper } from "utils/API/ArticulosAPI";
import { Divider, useMediaQuery } from "@mui/material";

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const PDFViewer = ({ file }) => {
  const [numPages, setNumPages] = useState(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div>
      <div className="pdf-container">
        <Document
          file={file}
          onLoadSuccess={onDocumentLoadSuccess}
          onLoadError={(error) => console.error("Failed to load PDF:", error)}
          options={{
            cMapUrl: "https://cdn.jsdelivr.net/npm/pdfjs-dist/cmaps/",
            cMapPacked: true,
          }}
        >
          {Array.from(new Array(numPages), (el, index) => (
            <div key={`page_${index + 1}`} className="pdf-page-container">
              <Page
                pageNumber={index + 1}
                className="pdf-page"
                width={Math.min(windowWidth * 0.9, 900)}
              />
            </div>
          ))}
        </Document>
      </div>
      <div>
        <p className="page-counter">Páginas: {numPages}</p>
      </div>
    </div>
  );
};

const Paper = () => {
  let query = useQuery();
  const [paper, setPaper] = useState(null);

  useEffect(() => {
    async function go() {
      const paper = await getPaper(query.get("id"));
      setPaper(paper);
    }
    go();
    // eslint-disable-next-line
  }, []);

  const isXs = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const title = {
    fontFamily: "Nunito Sans, sans-serif",
    fontSize: isXs ? "30px" : "70px",
  };

  return (
    <div className="paper-container">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: "50px",
          width: "80vw",
        }}
      >
        <h2 style={title}>{paper?.title}</h2>
        <Divider
          style={{
            backgroundColor: "black",
            height: "2px",
            bottom: "-5px",
            width: "100%",
            left: 0,
            marginBottom: "20px",
          }}
        />
      </div>
      {paper?.paper && <PDFViewer file={paper.paper} />}
    </div>
  );
};

export default Paper;
