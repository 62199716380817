import { useEffect, useState } from "react";
import { getPreviewImageUrlFromProduct } from "utils/API/Pictures";
import { getAllProductsAPI } from "utils/API/Product";
import ProductList from "./ProductList";

export default function BestSellerSection() {
  /* Variable con todos los Id's e informacion de los productos */
  const [products, setProducts] = useState([]);

  function compareProduct(a, b) {
    let A = (a.bestSeller ?? 0) === 0 ? 1000 : a.bestSeller;
    let B = (b.bestSeller ?? 0) === 0 ? 1000 : b.bestSeller;

    return A - B;
  }

  /* Obtiene todos los productos */
  async function getAllProducts() {
    let data = [];

    let types = [
      "KAT",
      "KAM",
      "CaAc",
      "Ac",
      "Flt",
      "Bj",
      "Accs",
      "Enf",
      "Cart",
    ];

    for (const productType of types) {
      const arrayProducts = await getAllProductsAPI(productType);
      arrayProducts.forEach(async (doc) => {
        // doc.data() is never undefined for query doc snapshots
        var url = null;
        await getPreviewImageUrlFromProduct(doc.id, productType)
          .then((r) => (url = r))
          .catch((e) => console.log(e))
          .finally(() => {
            data.push({ ...doc.data(), url, id: doc.id, type: productType });
          });
      });
    }
    const result = data.sort(compareProduct).slice(0, 4);
    setProducts(result);
  }

  useEffect(() => {
    getAllProducts();
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <ProductList products={products} />
    </div>
  );
}
