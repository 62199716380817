import useMediaQuery from '@mui/material/useMediaQuery';
import ShoppingCarProduct from 'components/ShoppingCarProduct/ShoppingCarProduct';
import ShoppingCarProductMobile from 'components/ShoppingCarProduct/ShoppingCarProductMobile';

/* Helpers */
import { convertToShoppingCartElement, saveShoppingCar } from 'helpers/helpers';

const container = {
  padding: '15px',
};

export default function Products({ items, updateItems }) {
  const isXs = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  function updateAmount(value, id, description) {
    const shoppingCar = convertToShoppingCartElement();
    const newShoppingCar = shoppingCar.map(item => {
      if (item.id === id && item.description === description) {
        return {
          ...item,
          amount: value > 0 && !isNaN(value) ? value : '',
        };
      }
      return item;
    });
    updateItems(newShoppingCar);
    saveShoppingCar(newShoppingCar);
  }

  function deleteItem(id, description) {
    const shoppingCar = convertToShoppingCartElement();
    const newShoppingCar = shoppingCar.filter(item => {
      return !(item.id === id && item.description === description);
    });
    updateItems(newShoppingCar);
    saveShoppingCar(newShoppingCar);
  }

  return (
    <div style={container}>
      {items !== null && items.map((item, idx) => (
        <div key={idx} style={{ marginBottom: '30px' }}>
          {isXs ? (
            <ShoppingCarProductMobile
              id={item.id}
              type={item.type}
              description={item.description}
              price={item.price}
              amount={item.amount}
              updateAmount={(v) => updateAmount(v, item.id, item.description)}
              deleteItem={() => deleteItem(item.id, item.description)}
            />
          ) : (
            <ShoppingCarProduct
              id={item.id}
              type={item.type}
              description={item.description}
              price={item.price}
              amount={item.amount}
              updateAmount={(v) => updateAmount(v, item.id, item.description)}
              deleteItem={() => deleteItem(item.id, item.description)}
            />
          )}
        </div>
      ))}
    </div>
  );
}
