import React from 'react';

import mapa from 'assets/img/mapa.png';
import { Divider, Grid, useMediaQuery } from '@mui/material';
import { Box } from '@material-ui/core';

function Nosotros() {
  const isXs = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  var pageStyle = {
    padding: "10px",
    paddingLeft: "10px",
    paddingRight: "10px",
    fontSize: "100%",
  };
  var titleStyle = {
    fontFamily: "Nunito Sans, sans-serif",
    fontSize: isXs ? "30px" : "70px",
    fontWeight: "700",
    marginBottom: "10px",
  };

  var contentStyle = {
    padding: "10px"
  };

  const textSection = {
    fontFamily: "Poppins, sans-serif",
    fontWeight: "300",
  };

  return (
    <div style={pageStyle}>

      <Grid container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >

        <Grid container xs={10} md={8}
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <h2 style={titleStyle}>¿QUIÉNES SOMOS?</h2>
          <Divider
            style={{
              backgroundColor: "black",
              height: "2px",
              bottom: "-5px",
              width: "100%",
              left: 0,
            }}
          />
        </Grid>

        <Grid container xs={10} md={8}
          align="left"
          style={contentStyle}
        >
          <div>
            <p style={textSection}>
              AFINAZONE es una marca que nació en la ciudad de Celaya, Guanajuato la cual se especializa
              en la venta, distribución de refacciones y autopartes en toda la república mexicana a través
              de plataformas en línea como lo es esta página web y también Mercado Libre, así pudiendo
              ofrecer una gran variedad de productos que cubran y excedan las necesidades de nuestro
              cliente principalmente en el mantenimiento de su automóvil.
            </p>

            <p style={textSection}>
              En AFINAZONE sabemos de la importancia del correcto mantenimiento preventivo del auto tanto
              del motor como de la transmisión por lo que diseñamos esta página para poder enfocarnos a
              ofrecer los productos necesarios específicos para el modelo de su automóvil pues sabemos que
              en ocasiones es difícil adquirir las refacciones que sean compatibles con su vehículo, es muy
              importante para nosotros brindarle a nuestros clientes la facilidad en el proceso de compra
              referente a compatibilidad del producto, esta página está diseñada para que con información
              básica de su auto y sin necesidad de conocer tecnicismos pueda decidir entre los productos
              compatibles según la información que va ingresando, asegurando que el producto que compra
              sea el que requiere.
            </p>

            <p style={textSection}>
              Nos interesa que nuestros clientes estén informados acerca de un mantenimiento correcto de su
              vehículo, además de conocer información general la cual es recomendable que cualquier conductor o
              dueño del vehículo conozca ya que puede ser de mucha ayuda e incluso le puede ahorrar dinero y una
              parte muy importante que es su seguridad por lo que ponemos a su disposición una sección de
              <a href='/Tips'> Tips para tu vehículo</a> donde encontrarás este tipo de información y más.
            </p>

            <p style={textSection}>
              En nuestro catálogo manejamos refacciones y kits con productos de marcas líderes en la categoría,
              dándole certeza que está adquiriendo productos de calidad al mejor precio.
            </p>

            <p style={textSection}>
              Ahora mismo tenemos a su disposición productos como lo son kits de afinación para transmisión
              automática y motor, enfriadores de aceite, cárter para transmisión automática, aceite de motor y
              transmisión, bujías, filtros, entre otros productos.
            </p>

            <p style={textSection}>
              Nosotros te ayudaremos a encontrar la pieza o kit que necesita tu vehículo con la confianza de
              contar con garantía, envíos a la puerta de tu casa y cambios o devoluciones.
            </p>

            <p style={textSection}>
              En AFINAZONE contamos con envíos a toda la república mexicana, no te preocupes, nosotros podemos
              surtir el producto que necesitas.
            </p>
            <div style={{
              display: 'flex',
              justifyContent: 'center',
            }}>
              <Box
                component='img'
                src={mapa} alt="AF"
                sx={{
                  height: { xs: '200px', md: '400px' },
                  width: { xs: '250px', md: '500px' },
                }}
              />
            </div>
          </div>
        </Grid>

        <Grid container xs={10} md={8}
          direction="row"
          justifyContent="center"
          alignItems="center"
          style={titleStyle}
        >
          <h2 style={titleStyle}>MISIÓN</h2>
          <Divider
            style={{
              backgroundColor: "black",
              height: "2px",
              bottom: "-5px",
              width: "100%",
              left: 0,
            }}
          />
        </Grid>

        <Grid container xs={10} md={8}
          align="left"
          style={contentStyle}
        >
          <div>
            <p style={textSection}>
              Satisfacer las necesidades de nuestros clientes a través de un amplio surtido en marcas y
              productos, siendo una parte muy importante la experiencia de compra de nuestro cliente,
              pues nos interesa que cualquier persona pueda comprar con facilidad y sin temor las piezas
              que realmente ocupe su vehículo.
            </p>
          </div>
        </Grid>

        <Grid container xs={10} md={8}
          direction="row"
          justifyContent="center"
          alignItems="center"
          style={titleStyle}
        >
          <h2 style={titleStyle}>VISIÓN</h2>
          <Divider
            style={{
              backgroundColor: "black",
              height: "2px",
              bottom: "-5px",
              width: "100%",
              left: 0,
            }}
          />
        </Grid>

        <Grid container xs={10} md={8}
          align="left"
          style={contentStyle}
        >
          <div>
            <p style={textSection}>
              Ser la marca líder en el mercado de kits para mantenimiento preventivo
              de transmisión automática y motor, enfriadores, cárter para transmisión,
              entre otros productos que ayude a los clientes a adquirir sus refacciones
              de manera sencilla y segura a través de plataformas de ventas digitales,
              siendo productiva y socialmente responsable.
            </p>
          </div>
        </Grid>
      </Grid>

    </div>
  );
}

export default Nosotros;
