import * as React from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import { getAllPapers } from "utils/API/ArticulosAPI";
import { getPreviewImageUrlFromPaper } from "utils/API/Pictures";
import { useHistory } from "react-router-dom";
import { Box } from "@mui/material";

export default function TitlebarImageList() {
  const [papers, setPapers] = React.useState([]);
  let history = useHistory();

  React.useEffect(function () {
    async function go() {
      let papers = await getAllPapers();
      for (const paper of papers) {
        paper.url = await getPreviewImageUrlFromPaper(paper.id);
      }
      papers = papers.sort((a, b) => a.order - b.order); // No limitamos a tres o cuatro aquí
      setPapers(papers);
    }
    go();
  }, []);

  function srcset(image, size, rows = 1, cols = 1) {
    return {
      src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
      srcSet: `${image}?w=${size * cols}&h=${
        size * rows
      }&fit=crop&auto=format&dpr=2 2x`,
    };
  }

  return (
    <>
      {/* Diseño para pantallas medianas */}
      <Box component={Box} sx={{ display: { xs: "none", md: "block" } }}>
        <ImageList
          sx={{ width: "1050px", height: "100%" }}
          variant="quilted"
          cols={3}
          rowHeight={250}
        >
          {papers.slice(0, 3).map((item, index) => (
            <ImageListItem
              key={item.img}
              cols={index === 0 ? 2 : 1}  // Personalización de columnas en md
              rows={index === 0 ? 2 : 1}  // Personalización de filas en md
              style={{ cursor: "pointer" }}
              onClick={() => history.push(`/Paper?id=${item.id}`)}
            >
              <img
                {...srcset(item.url, 10, item.rows, item.cols)}
                alt={item.title}
                loading="lazy"
              />
              <ImageListItemBar
                title={item.descriptionGAllery}
                sx={{
                  "& .MuiImageListItemBar-title": {
                    whiteSpace: "normal",
                  },
                }}
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
    
      {/* Diseño para pantallas pequeñas (2 por fila en xs) */}
      <Box component={Box} sx={{ display: { xs: "block", md: "none" } }}>
        <ImageList sx={{ width: "320px", height: "300px" }} cols={2} rowHeight={150}>
          {papers.slice(0, 4).map((item) => (
            <ImageListItem
              key={item.url}
              cols={1}
              style={{
                width: "100%",
                cursor: "pointer",
              }}
              onClick={() => history.push(`/Paper?id=${item.id}`)}
            >
              <img
                style={{
                  width: "100%",
                  height: "100%",
                }}
                src={item.url}
                alt={item.title}
                loading="lazy"
              />
              <ImageListItemBar
                title={item.descriptionGAllery}
                sx={{
                  "& .MuiImageListItemBar-title": {
                    whiteSpace: "normal",
                    fontSize: "12px",
                    lineHeight: "1",
                  },
                }}
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
    </>
  );
}
