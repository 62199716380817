import React from "react";
import WhatsAppIcon from "@mui/icons-material/WhatsApp"; // Si usas Material-UI, puedes usar este ícono.

const WhatsAppButton = () => {
  const handleWhatsAppClick = () => {
    const phoneNumber = "524613126256"; // Reemplaza con tu número de WhatsApp.
    const message = "¡Hola! Estoy interesado en tus productos.";
    const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
    window.open(url, "_blank");
  };

  return (
    <button
      onClick={handleWhatsAppClick}
      style={{
        position: "fixed",
        bottom: "20px",
        right: "20px",
        backgroundColor: "#25D366",
        color: "white",
        border: "none",
        borderRadius: "50%",
        width: "60px",
        height: "60px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
        cursor: "pointer",
        zIndex: 1000,
      }}
      aria-label="Contactar por WhatsApp"
    >
      <WhatsAppIcon style={{ fontSize: "32px" }} />
    </button>
  );
};

export default WhatsAppButton;
